.footer-component {
  background-color: #2b3467;
  display: flex;
  flex-wrap: wrap;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #ffffff;
  width: 100%;
}

.footer-divs {
  text-align: right;
  width: 30vw;
  height: 30vh;
}

.footer-divs h4 {
  font-weight: 400;
  padding-bottom: 4px;
  border-bottom: 2px solid #ffffff;
}

.footer-bottom-creator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: IranSans-Bold;
  color: #ffffff;
  width: 100%;
  padding: 16px;
  margin-top: 36px;
}

.links {
  display: flex;
  flex-direction: column;
}
.links a {
  display: flex;
  align-items: center;
  margin: 2px 0;
  color: #ffffff;
  text-decoration: none;
}

.downloadApp {
    /* padding: 10px; */
    /* margin-left: 20px; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 48px;
    width: 50%;
    margin: 50px auto;
    flex-wrap: wrap;
}
.downloadApp img {
  width: 130px;
  aspect-ratio: 16/9;
  object-fit: contain;
}

.downloadApp2 {
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.downloadApp2 img {
  width: 120px;
  aspect-ratio: 16/9;
  object-fit: contain;
}

.downloadAppButton {
  margin: 0 12px;
}

@media (max-width: 1366px) {
  .downloadAppButton {
    margin: 0 6px;
  }
}

@media (max-width: 900px) {
  .downloadAppButton {
    margin: 0 6px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    padding: 11px;
  }

  .footer-divs {
    text-align: right;
    width: 90vw;
    height: 30vh;
  }
}

@media (max-width: 500px) {
  .downloadApp {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 48px;
  }
  .footer-bottom-creator {
    display: none;
  }
}
