.Slider-Component {
  position: relative;
  margin-top: 77px;
}

.mySwiper {
  height: 85vh;
  direction: ltr;
  z-index: 1;
}

.slider-text {
  font-size: 20px;
  margin-top: 30vh;
  position: absolute;
  z-index: 2;
  width: 100%;
  text-align: center;
}

.slider-text h1 {
  background: -webkit-linear-gradient(#edbb11, #f3630f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.slider-text h1:hover {
  background: -webkit-linear-gradient(#f3630f, #edbb11);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 900px) {
  .mySwiper {
    height: 60vh;
    direction: ltr;
    z-index: 1;
  }
  .slider-text {
    font-size: 20px;
    margin-top: 16vh;
    position: absolute;
    z-index: 2;
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 720px) {
  .mySwiper {
    height: 40vh;
    direction: ltr;
    z-index: 1;
  }
  .slider-text {
    font-size: 14px;
    margin-top: 8vh;
    position: absolute;
    z-index: 2;
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .mySwiper {
    height: 30vh;
    direction: ltr;
    z-index: 1;
  }

  .slider-text {
    font-size: 8px;
    margin-top: 8vh;
    position: absolute;
    z-index: 2;
    width: 100%;
    text-align: center;
  }
}