.menu-button{
    display: none;
}

@media (max-width: 900px) {
    .menu-button{
        display: block;
    }
}
.re{
    background-color: red !important;
    top: 10px;
    left: 10px;
}