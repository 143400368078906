.Header-component{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    z-index: 10;
    background-color: rgb(232, 232, 232) !important;
    padding: 5px 0;
}
.header-div{
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-menu ul {
    display: flex;
    align-items: center;
}

.header-menu ul li{
    list-style-type: none;
    margin: 0 48px;
    cursor: pointer;
    color: rgb(0, 0, 0) !important;
    display: inline-block;
    text-decoration: none;
}
.header-menu ul li a{
  
    color: rgb(0, 0, 0) !important;

}

.header-menu ul li::after{
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #000000;
    transition: width .3s;
}

.header-menu ul li:hover::after{
    width: 100%;
}


.header-btn{
    padding: 8px 20px;
    border: 2px solid rgb(0, 0, 0);
    background-color: transparent;
    border-radius: 12px;
    color: rgb(0, 0, 0);
    border-radius: 8px;
    display: flex;
    align-items: center;
    transition: 0.4s ease;
    justify-content: space-between;

}

.header-btn:hover{
    background-color: #3d3b6d;
    color: white;
    border: 2px solid rgba(0, 0, 0, 0);
}

@media (max-width: 1366px) {
    .header-menu ul li{
        margin: 0 24px;
    }
}

@media (max-width: 900px) {
    .header-menu ul{
        display: none;
    }
    .header-btn{
        display: none;
    }
    .header-div{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}