.img-res {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

.clearfix {
  clear: both;
}

[class^="col-"] {
  float: right;
  padding: 0 15px;
}
.wrapper {
  width: 80%;
  margin: 150px auto 60px auto;
  padding: 10px 20px;
}
.wrapper .headerSpinner h1 {
  color: #2b3467;
  padding-right: 5px;
}
.wrapper .headerSpinner h1 span{
  color: #9b1c53;
  padding-right: 5px;
  font-size: 30px;
}
.wrapper .headerSpinner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px dashed #9b1c53d0;
}
.wrapper .headerSpinner2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px dashed #ea882b;
}
.wrapper .headerSpinner2 h1 span{
  color: #ea882b;
  background: -webkit-linear-gradient(180deg ,#ff7b00, #17759d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-right: 5px;
  font-size: 30px;
}
.container {
  overflow: hidden;
  padding: 10px;
  max-width: 70%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 5px;
  margin-bottom: 100px;
}

.c1 {
  grid-column: 1/ 3;
  grid-row: 1/3;
}
.c7 {
  grid-column: 2/ 4;
  grid-row: 3/5;
}
.c13 {
  grid-column: 3 / span 2;
  grid-row: 5 / span 2;
}
.c17 {
  grid-column: 2/ 4;
  grid-row: 7/ 9;
}
