.Body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RabetCatalog {
  background-color: #2b3467;
  margin-bottom: 50px;
  width: 100%;
  color: rgba(255, 255, 255, 0.966);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
}
.catalogimg {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 48px;
}
.catalogimgsize {
  max-width: 90%;
  padding: 20px;
}
.catalogtext {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 48px;
}
.text {
  width: 90%;
  padding: 24px;
  margin: auto;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: right;
}
.textdis {
  font-size: 18px;
}
.btncnter {
  width: 100%;
  margin-top: 20px;
}
.catalogdownload {
  background-color: #f3630f;
  color: white;
  width: 120px;
  padding: 8px 15px;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 12px;
  text-decoration: none;
}

.about-rabet {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 50px 0;
}

.about-right {
  width: 40%;
}

video {
  border-radius: 12px;
  width: 100%;
}

.about-spans {
  display: flex;
  align-items: center;
}

.we-products {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category-item {
  width: 16%;
  background-color: #2b3467;
  margin: 12px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-radius: 10px;
  color: #ffffff;
  text-align: center;
}

.contact-us {
  display: flex;
  padding: 64px 0;
  margin-top: 50px;
  background-color: #2b3467;
  width: 100%;
  border-bottom: 1px solid #ffffff;
}

.inputs1 {
  display: flex;
  width: 100%;
  padding: 8px;
  align-items: center;
  justify-content: space-between;
}
.inputs1 input {
  width: 100%;
  margin: 0px 12px;
}
.inputs {
  display: flex;
  flex-direction: column;
  padding: 8px;
  width: 100%;
}
.inputs input {
  margin: 0 12px;
  margin-bottom: 12px;
  text-align: right;
}
input {
  outline: none;
  border: none;
  text-align: right;
  padding: 12px;
  background-color: #ffffff;
  color: #2b3467;
  font-family: IranSans-Regular, sans-serif;
  border-radius: 8px;
  -webkit-box-shadow: -5px 5px 17px -1px rgba(0, 0, 0, 0.36);
  box-shadow: -5px 5px 17px -1px rgba(0, 0, 0, 0.36);
}
input::placeholder {
  color: #2b3467;
}
.submit-form-btn {
  border: none;
  border-radius: 10px;
  background-color: #f3630f;
  padding: 8px 24px;
  color: #ffffff;
  font-family: IranSans-Regular, sans-serif;
  cursor: pointer;
}
.submit-div {
  margin: 0 24px;
  text-align: right;
}

.contact-box {
  direction: rtl;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.contact-about {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.adress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  color: #ffffff;
  width: 100%;
}
.phones {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.Icons {
  background-color: #f3630f;
  padding: 6px;
  color: #ffffff;
  border-radius: 50%;
  margin-left: 10px;
}

.about-title {
  font-weight: bold;
  color: #ffffff;
  border-bottom: 2px solid;
  font-size: 24px;
  padding-bottom: 8px;
  margin-bottom: 12px;
  width: 100%;
}

.logos-partners {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  direction: rtl;
  padding: 64px 0;
  background-color: #2b3467;
  /* border-bottom: 1px solid #ffffff; */
}

/* --------------------------------- */
.products {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product_details {
  display: flex;
  flex-direction: column;
  width: 45%;
  padding: 64px;
}

.product_btn_enable {
  background-color: #f3630f;
  padding: 8px 24px;
  margin-top: 30px !important;
  margin: 0 12px;
  border-radius: 8px;
  border: 2px solid #f3630f;
  color: white;
}

.product_btn {
  background-color: transparent;
  padding: 8px 24px;
  margin-top: 30px !important;
  margin: 0 12px;
  border-radius: 8px;
  border: 1px solid black;
  transition: 0.3s all ease;
}

.product_btn:hover {
  background-color: #2b3467;
  color: white;
  border: 1px solid #2b3467;
}

.contact-form {
  width: 40%;
}

.about-logo {
  height: 110px;
}

.about-vid {
  width: 35%;
}

.partner-div {
  background-color: #2b3467;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.cable-img {
  width: 360px;
  border-radius: 12px;
  -webkit-box-shadow: 14px 10px 26px 1px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 14px 10px 26px 1px rgba(0, 0, 0, 0.37);
  box-shadow: 14px 10px 26px 1px rgba(0, 0, 0, 0.37);
}

@media (max-width: 1366px) {
  .about-rabet {
    width: 100%;
  }

  .about-logo {
    height: 80px;
  }

  .products {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .product_details {
    display: flex;
    flex-direction: column;
    width: 45%;
    padding: 64px;
  }
  .about-right {
    width: 55%;
  }
}

@media (max-width: 1280px) {
  .cable-img {
    width: 320px;
    border-radius: 12px;
    border: 1px solid #2b3467;
    -webkit-box-shadow: 14px 10px 26px 1px rgba(0, 0, 0, 0.37);
    -moz-box-shadow: 14px 10px 26px 1px rgba(0, 0, 0, 0.37);
    box-shadow: 14px 10px 26px 1px rgba(0, 0, 0, 0.37);
  }
  .product_details {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 64px;
  }
}

@media (max-width: 900px) {
  .cable-img {
    width: 200px;
    border-radius: 12px;
    border: 1px solid #2b3467;
    -webkit-box-shadow: 14px 10px 26px 1px rgba(0, 0, 0, 0.37);
    -moz-box-shadow: 14px 10px 26px 1px rgba(0, 0, 0, 0.37);
    box-shadow: 14px 10px 26px 1px rgba(0, 0, 0, 0.37);
  }
  .product_details {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 64px;
  }
  .products {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .product_btn {
    background-color: transparent;
    padding: 6px 20px;
    margin-top: 30px !important;
    margin: 0 8px;
    border-radius: 8px;
    border: 1px solid black;
  }
  .contact-box {
    direction: rtl;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .contact-about {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .contact-form {
    width: 80%;
  }
  .logos-partners {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    direction: rtl;
    padding: 64px 0;
    background-color: #2b3467;
    /* border-bottom: 1px solid #ffffff; */
  }
  .brand-logo {
    width: 200px;
  }
  .about-rabet {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 64px 0;
  }
  .about-right {
    width: 80%;
  }
  .about-vid {
    width: 64%;
    margin-top: 24px;
  }
  .about-top-title {
    width: 100%;
    text-align: center;
  }
  .catalogtext {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .text {
    width: 60%;
    text-align: center;
    padding-bottom: 36px;
    margin: 0;
  }
  .catalogimg {
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: center;
  }
  .catalogimg img {
    width: 320px;
  }
}

@media (max-width: 720px) {
  .products {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .products-buttons-div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 32px;
    border-bottom: 2px solid black;
  }
  .product-title {
    text-align: center;
  }
}

@media (max-width: 500px) {
  .brand-logo {
    margin: 16px 0;
  }
  .contact-about {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .contact-about span {
    text-align: justify;
    text-justify: auto;
  }
  .products-buttons-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 32px;
    border-bottom: 2px solid black;
  }
  .product_details {
    width: 100%;
    padding: 64px 0px;
    padding-top: 0;
  }
  .products {
    width: 80%;
  }
  .about-bottom {
    display: flex;
    flex-direction: column;
  }
  .about-bottom-child {
    width: 100%;
    text-align: justify;
    text-justify: auto;
    font-size: 14px;
  }
  .logos-partners {
    padding: 0;
  }
}
